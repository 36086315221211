import React from 'react';

import Container from '../Container';
import Row from '../Container/row';

import './style.css';

import loadComponents from '../Loadable';

const Copyright = loadComponents('footer-copyright');
const Contact = loadComponents('footer-contact');
const OpeningHours = loadComponents('footer-opening-hours');
const Account = loadComponents('footer-account');
const Cookies = loadComponents('cookies');
const BannerCta = loadComponents('cta-banner');
const FooterCta = loadComponents('footer-cta');

export default function Footer({ quote }) {
  return (
    <>
      <BannerCta />
      <footer>
        <Container>
          <Row>
            <Contact />
            <OpeningHours />
            <Account />
          </Row>
          <Copyright />
        </Container>
      </footer>
      <Cookies />
      {!quote && <FooterCta />}
    </>
  );
}
